import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Config from "../config";
//import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

// export default new Vuetify({
//     theme: {
//         themes: {
//             light: {
//                 primary: Config.ACCENT_COLOR,
//             },
//         },
//     },
// });

export default new Vuetify({
    theme: {
        dark: {
            primary: Config.ACCENT_COLOR,
        },
    },
});
