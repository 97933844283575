<template>
    <v-container class="d-flex justify-space-between flex-column flex-grow-1">
        <div class="mb-10 mt-n4" v-if="usbSupported">
            <h6 class="text-h6 pb-4">Choose the OS Type</h6>
            <v-list-item class="d-flex justify-space-between mx-auto">
                <v-hover v-slot="{ hover }">
                    <v-card
                        class="mr-3"
                        max-width="340"
                        :elevation="hover ? 16 : 2"
                        :class="{ 'on-hover': hover }"
                    >
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-card-title>Private OS</v-card-title>
                                <v-card-text>
                                    <div>
                                        It is a complete custom OS build from
                                        scratch with all security measures in
                                        mind. All endpoints are secured with
                                        private mode and no third party apps are
                                        allowed.
                                    </div>
                                </v-card-text>
                                <v-divider class="mx-2"></v-divider>
                                <v-card-subtitle>Features</v-card-subtitle>
                                <v-card-text>
                                    <v-chip class="ma-1" small>
                                        <v-icon small left
                                            >mdi-lock-plus</v-icon
                                        >
                                        Post-Quantum Cryptography
                                    </v-chip>
                                    <v-chip class="ma-1" small>
                                        <v-icon small left
                                            >mdi-arrow-horizontal-lock</v-icon
                                        >
                                        End-to-end Encryption
                                    </v-chip>
                                    <v-chip class="ma-1" small>
                                        <v-icon small left>mdi-key</v-icon>
                                        User Controlled Keys
                                    </v-chip>
                                    <v-chip class="ma-1" small>
                                        <v-icon small left
                                            >mdi-folder-lock</v-icon
                                        >
                                        Full-disk Encryption
                                    </v-chip>
                                    <v-chip class="ma-1" small>
                                        <v-icon small left
                                            >mdi-shield-lock</v-icon
                                        >
                                        Brute-Force Protection
                                    </v-chip>
                                    <v-chip class="ma-1" small>
                                        <v-icon small left
                                            >mdi-lock-reset</v-icon
                                        >
                                        Zero Log Policy
                                    </v-chip>
                                </v-card-text>
                                <div class="d-flex ma-2 justify-center">
                                    <v-btn
                                        color="primary"
                                        @click="setOSType('private')"
                                        :disabled="!usbSupported"
                                    >
                                        Select
                                    </v-btn>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-hover>
                <v-hover v-slot="{ hover }">
                    <v-card
                        class="ml-3"
                        max-width="350"
                        :elevation="hover ? 16 : 2"
                        :class="{ 'on-hover': hover }"
                    >
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-card-title>Public OS</v-card-title>
                                <v-card-text>
                                    <div>
                                        Includes all of the security measures of
                                        SecureOS but also comes with preloaded
                                        third party apps installed in the public
                                        mode.
                                    </div>
                                </v-card-text>
                                <v-divider class="mx-4"></v-divider>
                                <v-card-subtitle>Included apps</v-card-subtitle>
                                <v-card-text>
                                    <v-chip class="ma-1">
                                        <v-icon left>mdi-email-variant</v-icon>
                                        ProtonMail
                                    </v-chip>
                                    <v-chip class="ma-1">
                                        <v-icon left>mdi-key</v-icon>
                                        ProtonVPN
                                    </v-chip>
                                    <v-chip class="ma-1">
                                        <v-icon left>mdi-phone</v-icon>
                                        Silent Phone
                                    </v-chip>
                                    <v-chip class="ma-1">
                                        <v-icon left>mdi-send-circle</v-icon>
                                        Telegram
                                    </v-chip>
                                    <v-chip class="ma-1">
                                        <v-icon left
                                            >mdi-transit-connection-horizontal</v-icon
                                        >
                                        Threema
                                    </v-chip>
                                    <v-chip class="ma-1">
                                        <v-icon left>mdi-wallet</v-icon>
                                        Trust Wallet
                                    </v-chip>
                                    <v-chip class="ma-1">
                                        <v-icon left
                                            >mdi-message-text-lock</v-icon
                                        >
                                        Wickr Me
                                    </v-chip>
                                    <v-chip class="ma-1">
                                        <v-icon left>mdi-chat</v-icon>
                                        Olvid
                                    </v-chip>
                                    <v-chip class="ma-1">
                                        <v-icon left>mdi-alpha-w-circle</v-icon>
                                        Wire
                                    </v-chip>
                                </v-card-text>
                                <div class="d-flex ma-2 justify-center">
                                    <v-btn
                                        color="primary"
                                        @click="setOSType('public')"
                                        :disabled="!usbSupported"
                                    >
                                        Select
                                    </v-btn>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-hover>
            </v-list-item>
        </div>
        <div class="mb-10 mt-n4" v-else>
            <h6 class="text-h6 pb-4 red--text text--darken-4">
                Your browser isn’t supported
            </h6>

            <div class="text-body-1">
                <p>
                    Unfortunately, you can’t use this web installer for
                    {{ $root.$data.OS_NAME }} because your browser doesn’t
                    support it. Only Google Chrome and other browsers based on
                    Chromium, such as Brave and Microsoft Edge, are supported.
                </p>
            </div>

            <div class="text-body-1 mt-4">
                <p>
                    If you think this is a mistake, update your browser to the
                    latest version and try again.
                </p>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    props: ["device", "blobStore", "active"],

    data: () => ({
        usbSupported: "usb" in navigator,
        firstSet: true,
    }),

    watch: {
        active: async function (newState) {
            if (newState) {
                this.saEvent("step_choose");
            }
        },
    },

    methods: {
        setOSType(newType) {
            this.$root.$data.osType = newType;

            if (this.firstSet) {
                this.firstSet = false;
                this.$bubble("nextStep");
            }

            this.saEvent(`os_type__${newType}`);
        },
    },
};
</script>
