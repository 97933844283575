export default {
    OS_NAME: process.env.VUE_APP_OS_NAME,
    ACCENT_COLOR: "#5ab031",
    SUPPORTED_DEVICES: [
        {
            name: "Pixel 4a",
            model: "sunfish",
        },
        {
            name: "Pixel 4a 5G",
            model: "bramble",
        },
        {
            name: "Pixel 5a",
            model: "barbet",
        },
        {
            name: "Pixel 6",
            model: "oriole",
        },
        {
            name: "Pixel 6 Pro",
            model: "raven",
        },
        {
            name: "Pixel 6a",
            model: "bluejay",
        },
        {
            name: "Pixel 7",
            model: "panther",
        },
        {
            name: "Pixel 7 Pro",
            model: "cheetah",
        }
    ],
    RELEASE_VARIANTS: {
        full: {
            description: "Full version of SecureOS with clean install.",
            suffix: "",
        },
        private: {
            description: "Full version of SecureOS with clean install.",
            suffix: "",
        },
        public: {
            description: "Version of SecureOS with preloaded third party apps included.",
            suffix: "with-apps",
        },
    },
};
