import * as common from "./common.js";
import * as openpgp from "openpgp";

const CACHE_DB_NAME = "BlobStore";
const CACHE_DB_VERSION = 1;

export class XhrError extends Error {
    constructor(status, statusText) {
        super(`HTTP error ${status} ${statusText}`);
        this.status = status;
        this.statusText = statusText;
        this.name = "XhrError";
    }
}

// This wraps XHR because getting progress updates with fetch() is overly complicated.
function fetchBlobWithProgress(url, onProgress) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();

    return new Promise((resolve, reject) => {
        xhr.onload = () => {
            resolve(xhr.response);
        };
        xhr.onprogress = (event) => {
            onProgress(event.loaded / event.total);
        };
        xhr.onerror = () => {
            reject(new XhrError(xhr.status, xhr.statusText));
        };
    });
}

export class BlobStore {
    constructor() {
        this.db = null;
    }

    async _wrapReq(request, onUpgrade = null) {
        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                resolve(request.result);
            };
            request.oncomplete = () => {
                resolve(request.result);
            };
            request.onerror = (event) => {
                reject(event);
            };

            if (onUpgrade !== null) {
                request.onupgradeneeded = onUpgrade;
            }
        });
    }

    async init() {
        if (this.db === null) {
            this.db = await this._wrapReq(
                indexedDB.open(CACHE_DB_NAME, CACHE_DB_VERSION),
                (event) => {
                    let db = event.target.result;
                    db.createObjectStore("files", { keyPath: "name" });
                    /* no index needed for such a small database */
                }
            );
        }
    }

    async saveFile(name, blob) {
        this.db.transaction(["files"], "readwrite").objectStore("files").add({
            name: name,
            blob: blob,
        });
    }

    async loadFile(name) {
        try {
            let obj = await this._wrapReq(
                this.db.transaction("files").objectStore("files").get(name)
            );
            return obj.blob;
        } catch (error) {
            return null;
        }
    }

    async clearDB() {
        try {
            let transaction = this.db.transaction(["files"], "readwrite");
            let objectStore = transaction.objectStore("files");
            objectStore.clear();
        } catch (error) {
            return null;
        }
    }

    async close() {
        this.db.close();
    }

    async download(url, onProgress = () => {}) {
        let filename = url.split("/").pop();
        let blob = await this.loadFile(filename);
        if (blob === null) {
            common.logDebug("Clearing DB");
            await this.clearDB();
            common.logDebug(`Downloading ${url}`);
            blob = await fetchBlobWithProgress(url, onProgress);
            common.logDebug("File downloaded, saving...");
            const decryptBlob = await this.decrypt(blob);
            let plainStr = await openpgp.stream.readToEnd(decryptBlob);
            let blobStr = new Blob([plainStr], { type: "application/zip" });
            plainStr=plainStr.slice(0, 2);
            blob = blobStr;
            await this.saveFile(filename, blobStr).then((res)=>{
                if(res){

                    blobStr = blobStr.slice(0, 2);
                }
            }).catch((err)=>{
                console.logDebug("🚀 ~ file: download.js ~ line 128 ~ BlobStore ~ download ~ err",err);
            });
            common.logDebug("File saved");
        } else {
            common.logDebug(
                `Loaded ${filename} from blob store, skipping download`
            );
        }

        return blob;
    }

    async decrypt(blob) {
        const privateKeyArmored = `-----BEGIN PGP PRIVATE KEY BLOCK-----

lQdGBGCFMqoBEADvUeYD9Tw+9EJXQjLdb64VoF6mNAFjeHCRaC7REz5Nq0tdr9YB
9YOug6Y9SyAoCLJcQ8xc5V0NZrVeWcfmXatOWjS/EqKgnYatnslnIKbhg3dnxjX5
xrThiTnRRSdQDm2wsyS2LmDlFDPiHzwmkCzZIQ4VdRBduOcbUIYmRwLAZMHNsZGo
vJ8PBrAE5564/PUDanKI0RcbMAE4q/RjP+gI6vUuNTFIj7TzPmA/5tLw2a32136R
f29+aljI1oQ4qhdXn9ih5zJR6wyKwG/3FKdVwVqVZDppdadsrhS3Olw3UaIqcULA
rZH1VP5Erilrb27J64PR9V2nDCpr3I0yDgKm6INBTLE1nvZNT8gY7xuntSJusWSb
8/17gl9w7/eX7BnLc197Xve5OHX0LdJkJwev9WFnA6CrrKUMxCuocKEeqCyeJWYj
nZ28kJxysucCHpV9Gm3LzcXFlxeVFh6utmxIBPZgbfAZbgS504SajrvlYVIjQXLj
C09H8yKr0iLaSGYK7a+fhN+BmUKIRpxJKoZ2pTDHijX+oAgazSJzY59+je0JEUHX
Zpuw06/9Pqwfyd0IeyH+br11u7kIs239OPBdhkDog3WmWF2YFXr6T8KizO/pQm6u
VgTTRGWDmyZ48l/d72DKk4GF9Hv0apVGmT8CpBpmR0eUSfs4iOZXeuR+rQARAQAB
/gcDAojugvgCIC8963f6qpAehC2+gcSmn4m/SCrTrqx7KCtA9ZlhHPZOXn/c78a9
P2B+t/oUNRoLtGE/FsXcHNzkwD+jLdx6XG/Hyhww2BAkj+kRivQGRVIk78LxF9HE
EWmuP/s3cPNZyYMpt0gZnjWqQDyywCoUqcT+AJeqlUtxeDVpkBzNKNuIPcv0hgMW
jVjF9aUBrhmxkX92096nvafoTl38+nk7QYsRzb9SLIGTZb08AloMBbyP9ZzLgIc/
2gQnp44zL12eFKnS82EzDXeAMs/b/YWTU5jsPsAAjLjBFzIhj3T42zJZ3bVlGmST
9ABwFA7QKV1zRdS+QFu/ItKlr2CIMTy3uPtT9ptUcICYy03XI2rXp4FCVz+0cmPg
07nDbPMkI66+IuuoM4o3Qi+3sAoocXMXzPA2wvwpN0x9UIA7MpIxaQXqStAn+gUd
ReINqV403MDU/rGN+EIIQx2Grr8SFtoWL0/mnnZXuKJcrkZIFlxyWYBf4tiqjrM+
1YgJvaGwbnxoDOhXp/YeJB46FDP96ypp/DYIZjwPiLRPiHRcBVDue5sf81QhxzS9
zOG0aWliPIfoNVJ4djpWdZOtRvCJdwq9Sx0fFfTN149E9inPo9ch5drORPGSVlfR
KF2xPk3wj4u5e4VsayzBmyteYucHlO2lJxG9Vw3Io6fEOcTyf0OImUOlG6GsL0wK
3s/kfkbx/1IZtmvdTuJAlGrkk1UOo6HwLYglle2+pdeiNsCDQf0iHdVJ64YXD0VF
otfKe/Sgs09TQ878bVr4ZArCjHLkI9Wwz8bc5fnmJTvl23nv186J8YBwhecw9tlP
Poe0Eei+EctLMHR3Ag0I1e+ezTjxh180/J9iqA0nTjcTnNP7EkoGNqLJAkV+H/p/
9avqz3PbneNxxfxFAy+leiMTEZD45SdjYv06CJ0chtKB2dQZ/rl1WG0EbzP/Se2t
86shJPwndIvDfLPbgAe3GFS/+czzh7i2q32crmoxlXcRggEjTqvHXB2z5nWu/hGM
ZHXcrpP8WhISXkFDUMepfr8kbHE+Ip7x52G+0UpWfgIHPT/fmZY28E00zCGmGwNM
aYEW7CdA4EowGBE6r8AI1gkuGUmWhnX2PT/AupNcUPawWgzrGOL0SVd9AwRkP4ae
5c8hQ/hSJpi5eNjr0RfQxuPWaYCONUZ0NeBt18xK8iFaYbGaLMxpZJrS9gEMx3ys
oh/7fE4q5wNmGi5Q/6EtQh75XcB8t+11Wj1Yvd5qdvla5/sCRngYSMEP+MbXMCnM
WC8LH5ilvfdDhHPXAb2lbobap/IOVHg0PfMP1Wuli3ZAQE8AYggO6LUQAEZp/RTM
v0I1ii9VYTBL7QY+EjJzOUGp65FS7sleVA/ckaxxMh0qWE+dnXh6kPWlnb21lz8S
usesq32NzvqPjsAo12gvglBIul1g/dQAUcfMWnpL8aDC/1suxxfcFflwblYi82eX
YeC69mxl+Anx5fw2yutWGS3Z+xGAF5GG7YVruMn7WpyFIJG/31Vn8HdJuWZUfLkW
qRT715uz3Tn+WagAliSg72h/oQg6YB8hg25wsmK/KziHoawf3yfDCBBpc5q8IbDi
09P5BLy3j+GML+VnTZ90HCg3UcAWlFkoGZHsydq3+1vOqASVGZ4IvO2hy7EbY9QZ
IeL8E0Q+rIyQ+UEPwDiDUtZpz1sJEc1SbJ/rxurJxDAov/3jPeG+BqJB+JheRmJf
lmINS9KlOgY51SDT2235EcbfQbDwPHamk+b7Ul/fQU/p3sf81mY8QZu0KE9TIFJl
bGVhc2UgPHJlbGVhc2Vvc0Bvc3JlbGVhc2UucmVsZWFzZT6JAlQEEwEIAD4WIQSz
hTggX/O0QzwOJM3IJZthZpoEiQUCYIUyqgIbAwUJB4YfKwULCQgHAgYVCgkICwIE
FgIDAQIeAQIXgAAKCRDIJZthZpoEiWxhEADRBaG2TLahhlT1nvRbSasehzHWFM8q
/Oodpx2TevwxV+xYZSh24lURpbZqbWH/DMbvpft8cUHa8Mn5xhEDMiT4V7OhumxF
H6v9fEnJ8daKY5OzJUuUCvwc2YdJu5rLhXaUS50oETKLEXmLlSHZW/YP8Qdfsz2g
dZU5XUHMzA5nO0Yydf0mhOj0gvAMyVaAl/+RXlJ2iaYeBx8c0xlMtHAk+uHRMy3j
v5rNIDABIOUZRqx6rKGQ7I7twp8ebLjkCHkeiiqrnO9pAdbD6Mp1s6R3D4DV7YBP
0SADYq5fY6l48MjoaQKdsvzAZYOSWtmgth0gRaFwhPdbcjHWKLcSeTLfEF0rudo1
uvR41/0KcwrWH+Aq0wAZGIcL4rvyN4oGqN4R58xF4wZMzv5H0rcDM/GxaHW0mIvn
6nlo1vbowHL4a1ql0SdF6SdW5vWGJ5E8GJTJHl15WxnEt/Ja70PRXlS/RKL605Nk
jPSyFaU0elVg0G3jSE7Tr45PKU3lt3AeGUgNNcx+/geenI3FyqGvlSa3Sinfm8gi
uQ6I9VP9bv+07pWe8gG9iNnb8lrNZaN4ZkTi7FXBj+6C8U+vOYt4XamLpp7bjiDS
itEZdQuVmg3jrSqz+0nqqrFfNgDaNMIEzXjSVeXXGXMOJq0rTshqRurFPEAxhgB/
uvCx730gB+ufLp0HRgRghTKqARAApgaMeON82lzA6ti9cfnLIbLliPRbro792ylc
XeaiAOK96Qcf2rPtiI5NZTpH+chSsKCvSTQzIRhwTe+sn3PAx3P+Y4aQ9TyTnult
vy++tC1FiizZtFMx/2awR7k8nhE9mMPk76Y44Dtgt1gGhiMb0lcp+tHjWAQL9ubB
dgBXhCaevkGsk8jJe2dNXkCMqFtGb9gTehkrPe+FHIe5QMKNvAd802dwbYB5JQ7O
rR3qVG8eG02wptm7OJR/WzIYtqCGILHskbvIp2ImozsYTa/e4+RKgcNpU+QKylBA
G7VLkBkKOI2USI9kaPtExwPS0ECd6uCKcFL+p3XAnC+NOddv79AQ2/iELtD5B36W
qqy39k9e8DP4rPmRy1xBI7nmGCT4W6W7ZSUW1UuOYcsV85s1imBXdfZc0UF/V9rV
9f2heM4Nrb/egZNctKxHOCMfpuaKy+e9yObwFm1jsFOJXYUBnB0gci1M44GHjGkg
f/F+C0i1o+lljwJwQKAOBZMfNYnvQpWxHoJTpAPccRaUKMjfg0+j7g6KTxP9lu/a
tT1KHbgPdrgkCyGYPjcwMe0J7IDNJXlPhdK5RPaVax6vh69Kltx7M5ohv5voH6v2
Y72eaIBpbf60+XAiT6Ull0O1pTANr8e8E5aZ0QLOgnmi+9YBOvq+yNagLGnBp+sm
xLHK33EAEQEAAf4HAwL7MwTsq1DHLevjha4AQmBsgG9OYHFy4d6LkekHMXpjYgpE
qKjJeWBbeZRczuNyV8U91pdngr58VQs9AQRk5UcFmurZefI+2RekWpWSg2UiE4ry
1Lv3OuDNrj4sc9UwOW91gHapr90MIJE/wXcPHUBsIAMvqd1s4nHal9gSK3GBMqo/
RmwcLd9I7lXWQBZq6PfRialn41ZwWhx3EjQ+8veU8Piz7cA/CwpvfQV0dc3Hi4Wn
FRXmBFb5yF5IQeS6cpuKFegOcGofsr5So5mORusdUB5NQ9S5ahfDCU+nwd2NTpqt
5CCItx7dIg3ctywQeAYWyH6OCeIaF49SS1OLOeoBa49aap29VhsXZq0V6dUzghZ+
WxNpR57+Zg+QCkLgakR2KLhdAJjvd+TculxYb2TBwQgDvrfkFTePK0O72KOrYFOd
V3+Kp6T34YELOAO6u4A40StHhkVJzN9QC4gJtaGQjXAS9gWZyTJVU22HrXgYmtlD
Mo7ceW9yFX4pnDm95ysfaBbPh2mYE5c2kozYuXBHyblV9DyFdaYtUI4b7/cBSz/U
7BuxKLHvqxzYOsyRzE05o1ig7oN1X4ZfZ/pklr1ph2DFdVHM+IcVz0t2Erh0LkIE
d8fFuiDz0Ci8CgyKjDOtuLgaOMExyeYH72uEsO3uhwTM1C95vgCQmLxvxUZ0BtLE
JpJTGk6IyZKTvEvlbcNZcsBzPxSuLqfu4ArAmUJLPgMxTv2uCIaIUugfTAbh2u+a
5BNHvWsFY0yueRsZUvtbCj/Ch0/m1A5jqOcpRSPX0NVgiOsvAWSO/4Fhu0o5h0kR
lNZqYHWAhFyNHGi9PnpawW1c9zEMVRFAr55rn9hWFsIeOl9khjm5seiJxWN/9TPG
CSX8tYhoqlMQQvFCg/P8HkUCjLnAGo9e7PjRfNT2SULr12lWXjyYMKxwpHIPhtEJ
TaYuy8GD/JsEF4fjQJSqg0gu4RcWE6KL3pomKnJek8OEsUIaRNJQge6O77i/JZaf
Fp6VFTzFUATFoJByIXBFZs8YgGORolT7OwA5XFo+nwS2J8NRdtlDSKtyEUwTlbSP
4NPMC675rr6HWV8ObVO9CqYYdg9devspInw8ezFG0PW0Du0OHwg9PxNORZB9mFh+
wlBhiQPLGzPtgjcm9JC7hXQzVgOXoKvJqmLxLCO4kk0U8JWBEshdV6qEykkcK7ch
wZXZe/ENh0GFM3IjBVeJ6D74AEoYAE2PDfibd/fWMpAU5dbzvENUEmZYn6Ii6ENl
VDjRu1rwwDt2NYuI4R8x8Eac99o27pTFFj2D82scZpuXBrSWIKUi6PpKTYIuYogX
3WJap/8HTF71p3TTJLv7e1I1zt8ISf/dbqXbbhk05OYLNeHG/1vJ5pQxRRDp6pzj
eCt4/EqIzwCWaRVQORmIgSS+Xl7nrKl5KDpPRU+xuD+rbYY0756ZAXRM2i5mOEsG
qjVmODvH6br8FSL+JZt8lnFgUseAePCypVBonV0H8DFT3qCZMtk7zX9sQC43nLsB
jSuYTulYvWz2sV4FqElAhUMbsOlBUE8TyqsNcPIXchscy2tm6718MmuO566sV/Cm
CzImcepfKurla0XrTck2a6zbhlHhu3Ioz9pBjioZ4e/Jq4JW9NOWSYrN4G5xoQeD
WEjnPiZQw/01/z/RcHxaT67iE1bKHftcWwU5sv1UoNkylTjSaWHH9Booogdi2xBa
E6+wAmtyR9uI+uCYgYTxq4NIjKRmjM8L+mBhdb0FrZD5IAYukljQEjycWqmKJ1sq
BDmDiQI8BBgBCAAmFiEEs4U4IF/ztEM8DiTNyCWbYWaaBIkFAmCFMqoCGwwFCQeG
HysACgkQyCWbYWaaBIkK9w//Z50fIE8WmtxvgVAJIMET/C+KwrDtZ32J/ZgNYcPO
xNRNV/FbZBz8/MgpiPa9WtrgT5dT1VH7AQfFuTlOKm1Fm9WL+IaznQlAtnIUMFRm
HXG3MthCDVrfVV4M3OnsWBxArxsYS5KZFwFcQQ56czah9SpyszfoxDb4S+9Erh93
2nvpodXCRsetDm2LWZkkLqvF/ToZOUQKiC3W1ghvDk/CPo35N9pX0E3xmOLVaSan
5sHujVSzWQ/PYllh3I0oYRRDfGWKkj7L/9PE0UdZUa+c5B8HP3yL9mn6TOkXSKZl
Ue+mNy921cPC8xiBIs7oPcpoSmPGYU1PCmE98eVG5fkbMygkfTLWuCRfejfeEJz6
Efmo2dw9N11PZuBAr7cy5gqcQsAbuvIn1i1f+aLuIF3hc0IMxMzs3GguxsUUH7iG
ZOqmLYl89/3rkRv5BAQs55fH5apBUPOCl+/0dsUqT8M0WMvXcJ74hEEPVzj8GpOO
TvRcS3IhZmbBv498506tvyTInuaHSuFhMCu6cqLXUR30Ys0YrWM5L7b88gLoX8LQ
tRRJkmsGj59Ri9dkK3m0ZU1yrWdbBD9gxmL8eZpu/GkqzATMVFw/XrsLnlxGXWIZ
/StQas85ph2BBMBvwqzQWLWw5MDjBtJPQOW0VvuARa0bwFa7ECGOtyuV3DVacgWp
a+Q=
=9MNa
-----END PGP PRIVATE KEY BLOCK-----
`;
        const passphrase = "GqO6Mo^eJMOm";
        const privateKey = await openpgp.decryptKey({
            privateKey: await openpgp.readKey({
                armoredKey: privateKeyArmored,
            }),
            passphrase,
        });
        const message = await openpgp.readMessage({
            binaryMessage: blob.stream(),
        });
        const { data: decryptedBlob } = await openpgp.decrypt({
            message,
            privateKeys: privateKey,
            format: "binary",
            config: { allowUnauthenticatedStream: true },
        });
        return decryptedBlob;
    }
}
