const DEVICE_NAMES = {
    sunfish: "Pixel 4a",
    bramble: "Pixel 4a 5G",
    barbet: "Pixel 5a",
    oriole: "Pixel 6",
    raven: "Pixel 6 Pro",
    bluejay: "Pixel 6a",
    panther: "Pixel 7",
    cheetah: "Pixel 7 Pro",
};

export function getDeviceName(device) {
    return device in DEVICE_NAMES ? DEVICE_NAMES[device] : device;
}
