/* eslint-disable no-useless-escape */
/* eslint-disable no-useless-escape */
<template>
    <v-container class="d-flex justify-space-between flex-column flex-grow-1">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
                v-model="username"
                :counter="10"
                :rules="usernameRules"
                label="Username"
                required
            ></v-text-field>

            <v-text-field
                autocomplete="current-password"
                :value="userPassword"
                label="Enter password"
                hint="Your password passed! Password rules are not meant to be broken!"
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
                :rules="[rules.password]"
                @input="(_) => (userPassword = _)"
            ></v-text-field>

            <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
            >
                Validate
            </v-btn>

            <v-btn color="error" class="mr-4" @click="reset">
                Reset Form
            </v-btn>
        </v-form>
        <v-snackbar v-model="snackbar">
            Success
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { post } from "axios";
import store from "store";
export default {
    props: ["device", "blobStore", "active"],
    data: () => ({
        valid: true,
        snackbar: false,
        username: "",
        userPassword: "",
        value: true,
        usernameRules: [
            (v) => !!v || "Name is required",
            (v) =>
                (v && v.length <= 10) || "Name must be less than 10 characters",
        ],
        rules: {
            required: (value) => !!value || "Required.",
            password: (value) => {
                // eslint-disable-next-line no-useless-escape
                const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
                return (
                    pattern.test(value) ||
                    "Min. 8 characters with at least one capital letter, a number and a special character."
                );
            },
        },
    }),
    watch: {
        active: async function (newState) {
            if (newState) {
                this.saEvent("step_login");
            }
        },
    },

    methods: {
        validate() {
            console.log(process.env.VUE_APP_LOGIN_URL);
            const api = `${process.env.VUE_APP_LOGIN_URL}`;
            const url = api + "/authenticate";
            var data = {
                username: this.username,
                password: this.userPassword,
            };
            post(url, data).then((response) => {
                if (response.status === 200) {
                    store.set("userid", { username: response.data.id });
                    <v-alert type="success">Success</v-alert>;
                    this.$bubble("nextStep");
                } else {
                    <v-alert type="error">Invalid Credentials</v-alert>;
                }
            });
        },
        reset() {
            this.$refs.form.reset();
        },
    },
};
</script>