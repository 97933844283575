<template>
    <v-container class="d-flex justify-space-between flex-column flex-grow-1">
        <div class="mt-n4">
            <h6 class="text-h6 pb-4">Installation complete</h6>

            <div class="text-body-1" v-if="$root.$data.release !== null">
                <p>
                    Congratulations! Your
                    {{ getDeviceName($root.$data.product) }} is now running
                    {{$root.$data.osType}} {{ $root.$data.OS_NAME }} {{ $root.$data.release.version }}.
                </p>
                <p style="margin-top=10px">
                    Note: Your device will ask permission to lock the
                    bootloader. Kindly confirm using the volume down and power
                    buttons. Press power button again to restart once the
                    bootloader is locked.
                </p>
            </div>
        </div>

        <div class="d-flex justify-space-between flex-row-reverse">
            <v-btn color="primary" @click="reloadPage"
                >Start Again <v-icon dark right>mdi-arrow-right</v-icon></v-btn
            >
        </div>
    </v-container>
</template>

<style>
.theme--light.v-sheet--outlined {
    border-width: 2px;
}

.theme--light.v-sheet--outlined.v-card--p-highlight {
    border: 2px solid #007cfa !important;
}
</style>

<script>
import { getDeviceName } from "../core/devices";

export default {
    name: "FinishStep",

    props: ["device", "blobStore", "active"],

    data: () => ({
        locking: false,
        locked: undefined,
        initialLocked: undefined,
        error: null,
        oemLockDialog: false,
    }),

    watch: {
        active: async function (newState) {
            if (newState) {
                this.saEvent("step_finish");
            }
        },
    },

    methods: {
        getDeviceName,
        reloadPage() {
            window.location.reload();
        },
    },
};
</script>
